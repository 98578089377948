<template>
    <div class="row" id="gallery">
        <div v-for="(gallery, i) in galleries" :key="i + gallery.index"  class="wrapper" @click="openGallery(gallery)">
            <img :src="getPreview(gallery.data[gallery.index])" data-aos="fade-up">
            <div class="gallery-overlay">
                <h5>{{gallery.name}}</h5>
            </div>
        </div>

        <GalleriesModal :gallery="gallery" @close="closeGallery"/>
    </div>
</template>

<script>
import GalleriesModal from './GalleriesModal.vue';

export default {
    name: 'Galleries',
    components: {
        GalleriesModal,
    },
    mounted() {
        const fullDelay = 10000;
        const delay = fullDelay / this.galleries.length;
        this.galleries.forEach((gallery, i) => {
            setTimeout(() => {
                setInterval((() => {
                    this.nextImage(gallery);
                }), fullDelay);
            }, i * delay);
        });
    },
    methods: {
        nextImage(gallery) {
            let nextIndex = gallery.index + 1;
            if (nextIndex >= gallery.data.length) {
                nextIndex = 0;
            }

            gallery.index = nextIndex;
        },
        openGallery(gallery) {
            this.gallery = gallery;
        },
        closeGallery() {
            this.gallery = null;
        },
        getPreview(data) {
          if (data.type == 'image') {
            return `/assets/resized/galleries/${data.src}`
          } else {
            return `https://img.youtube.com/vi/${data.src}/maxresdefault.jpg`
          }
        }
    },
    data () {
        return {
            gallery: null,
            galleries: [
                {
                    name: 'Новости',
                    index: 0,
                    data: [
/*                         { type: 'image', src: 'news/11.jpg' },
                        { type: 'image', src: 'news/10.jpg' }, */
                        { type: 'image', src: 'news/9.jpg' },
                        { type: 'image', src: 'news/8.jpg' },
                        { type: 'video', src: 'nci6Z_PAmE0' },
/*                         { type: 'video', src: '5Mn1V4kAMGQ' }, */
                        { type: 'image', src: 'news/1.jpg' },
                        { type: 'image', src: 'news/2.jpg' },
                        { type: 'image', src: 'news/3.jpg' },
                        { type: 'image', src: 'news/5.jpg' },
                        { type: 'image', src: 'news/6.jpg' },    
                        { type: 'image', src: 'news/7.jpg' },    
                        { type: 'video', src: 'v9YhynCjEJM' },
                        { type: 'video', src: 'Y-_hY9Z7fqo' },    
                    ],
                },
                {
                    name: 'Галерея',
                    index: 0,
                    data: [
                        { type: 'image', src: 'view/1.jpg' },
                        { type: 'image', src: 'view/2.jpg' },
                        { type: 'image', src: 'view/3.jpg' },
                        { type: 'image', src: 'view/4.jpg' },
                        { type: 'image', src: 'view/5.jpg' },
                        { type: 'image', src: 'view/6.jpg' },
                        { type: 'image', src: 'view/7.jpg' },
                        { type: 'image', src: 'view/8.jpg' },
                        { type: 'image', src: 'view/9.jpg' },
                    ],
                },
                {
                    name: 'Шоурум',
                    index: 0,
                    data: [
                        { type: 'image', src: 'showroom/1.jpg' },
                        { type: 'image', src: 'showroom/2.jpg' },
                        { type: 'image', src: 'showroom/3.jpg' },
                        { type: 'image', src: 'showroom/4.jpg' },
                        { type: 'image', src: 'showroom/5.jpg' },
                        { type: 'image', src: 'showroom/6.jpg' },
                        { type: 'image', src: 'showroom/7.jpg' },
                        { type: 'image', src: 'showroom/8.jpg' },
                        { type: 'image', src: 'showroom/9.jpg' },
                    ],
                },
                {
                    name: 'Инфраструктура',
                    index: 0,
                    data: [
                        { type: 'image', src: 'relaxation/1.jpg' },
                        { type: 'image', src: 'relaxation/2.jpg' },
                        { type: 'image', src: 'relaxation/3.jpg' },
                        { type: 'image', src: 'relaxation/4.jpg' },
                        { type: 'image', src: 'family/1.jpg' },
                        { type: 'image', src: 'family/2.jpg' },
                        { type: 'image', src: 'family/3.jpg' },
                        { type: 'image', src: 'family/4.jpg' },
                        { type: 'image', src: 'family/5.jpg' },
                        { type: 'image', src: 'family/6.jpg' },
                        { type: 'image', src: 'sport/1.jpg' },
                        { type: 'image', src: 'sport/2.jpg' },
                        { type: 'image', src: 'sport/3.jpg' },
                        { type: 'image', src: 'sport/4.jpg' },
                        { type: 'image', src: 'sport/5.jpg' },
                        { type: 'image', src: 'sport/6.jpg' },
                        { type: 'image', src: 'sport/7.jpg' },
                        { type: 'image', src: 'sport/8.jpg' },
                        { type: 'image', src: 'sport/9.jpg' },
                        { type: 'image', src: 'sport/10.jpg' },
                        { type: 'image', src: 'sport/11.jpg' },
                        { type: 'image', src: 'sport/12.jpg' },
                        { type: 'image', src: 'sport/13.jpg' },
                    ],
                },
            ],
        }
    }
}
</script>

<style lang="scss" scoped>

    .row {
        display: flex;
        background: black;

        .wrapper {
            border: 4px solid black;
            border-right: none;
            box-sizing: border-box;
            max-width: 25vw;
            flex: 253%;
            min-height: 15vw;
            height: 15vw;
            position: relative;
            display: flex;
            overflow: hidden;
            text-align: right;

            img {
                width: 100%;
            }
            .box {
                padding-bottom: 56.25%;
            }
            h5 {
                padding: 0;
                margin: 0;
                color: white;
                font-size: 24px;
                font-weight: 500;
                place-self: flex-end;
                margin: 16px 24px;
            }

            .gallery-overlay {
                display: flex;
                position: absolute;
                top: 0;
                background-size: cover;
                background-image: linear-gradient(180deg, transparent, rgba(0, 0, 0, 0.6));
                width: 100%;
                height: 100%;
                overflow: hidden;
                cursor: pointer;
                
                flex-direction: column;
                justify-content: flex-end;
                align-items: flex-start;
                align-content: flex-start;

                .line {
                    width: 0;
                    height: 2px;
                    background: #8b8b8b;
                    margin: 2px 2px;
                }

                
                @media only screen and (max-width: 900px) {
                    h5 {
                        font-size: 20px !important;
                    }
                }

                
                @media only screen and (max-width: 760px) {
                    h5 {
                        font-size: 16px !important;
                    }
                }

                
                @media only screen and (max-width: 600px) {
                    h5 {
                        font-size: 24px !important;
                    }
                }
            }
        }

        .wrapper:first-child {
            border-left: none;
        }
    }

    @media only screen and (max-width: 600px) {
        .row {
            display: block !important;
        }

        .wrapper {
            max-width: 100% !important;
            border-bottom: none !important;
            border-left: none !important;
            border-top: 4px solid black !important;
            min-height: 60vw !important;
            height: 60vw !important;
        }

        .wrapper:last-child {
            border-bottom: 4px solid black !important;
        }
    }
</style>
